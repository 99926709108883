import React, { useRef } from "react"
import { VectorMap } from "react-jvectormap"

import './map.scss'

// const handleClick = (event, code) => {
//   console.log(`You have click ${code}`)
// }

const mapData = {
  CN: 120,
  EG: 100,
  KE: 100,
  GM: 100,
  SO: 100,
  NG: 100,
  SL: 100,
  GN: 100,
  MR: 100,
  ML: 100,
  ET: 100,
  TZ: 100,
  SN: 100,
  UG: 100,
  XS: 100
};

const AfricanMap = () => {
  const map = useRef()
  return (
    <section className="map-wrapper">
      <h2 id="where-we-operate">Where we operate</h2>
      <VectorMap
        map={"africa_mill"}
        backgroundColor="transparent"
        containerStyle={{
          width: "100%",
          height: "80vh",
        }}
        controls="none"
        regionStyle={{
          initial: {
            fill: "#e4e4e4",
            "fill-opacity": 0.9,
            stroke: "#ffffff",
            "stroke-width": 4,
            "stroke-opacity": .15
          },
          hover: {
            "fill-opacity": 0.8,
            cursor: "pointer"
          },
          // selected: {
          //   fill: "#2938bc" //color for the clicked country
          // },
          selectedHover: {}
        }}
        series={{
          regions: [
            {
              values: mapData, //this is your data
              scale: ["#5ca891", "#ff0000"], //your color game's here
              normalizeFunction: "polynomial"
            }
          ]
        }}
        // onRegionClick={handleClick}
        ref={map}
        containerClassName="map"
        zoomOnScroll={false}
      />
    </section>
  )
}

export default AfricanMap
